import "regenerator-runtime/runtime";

// sync imports
import {
  TAddBasketItem,
  TApplyGiftCard,
  TApplyPromoCode,
  TGetWishlistStatus
} from "./api/types";
import { initLazySizes } from "./lazysizes";
import { fallbackImage } from "./fallback-image";
import { passiveEventListeners } from "./passive-event-listeners";

if (
  navigator.userAgent.indexOf("MSIE") !== -1 ||
  navigator.appVersion.indexOf("Trident/") > -1
) {
  document.querySelector("#browser-compatible-banner")?.classList.add("show");
}


// Async imports

// create react+react-dom bundle
import("./react/utils/react-dom-import").then();

import("./react/basket").then(page => {
  page.basket();
});


declare global {
  /* tslint:disable */
  interface Window {
    /* tslint:enable */
    basketAPI: {
      addBasketItem: TAddBasketItem;
      addWishlistItem: TGetWishlistStatus;
      applyPromoCode: TApplyPromoCode;
      applyGiftCard: TApplyGiftCard;
      unApplyGiftCard: (giftCardNumber: string) => void;
      getBasketValues: () => DOMStringMap;
      refreshBasket: () => void;
    };
  }
}


import("./api/basketAPI").then(basketAPI => {
  window.basketAPI = {
    addBasketItem: basketAPI.default.addBasketItem,
    addWishlistItem: basketAPI.default.addWishlistItem,
    applyPromoCode: basketAPI.default.applyPromoCode,
    applyGiftCard: basketAPI.default.applyGiftCard,
    unApplyGiftCard: basketAPI.default.unApplyGiftCard,
    getBasketValues: basketAPI.default.getBasketValues,
    refreshBasket: basketAPI.default.refreshBasket
  };
});

var modules = {} as IModules
window.modules = modules

import("./validation").then();

import("./input").then(page => {
  page.initialize();
});

import("./modal").then(page => {
  page.modal();
  modules.modal = page.modal;
});

import("./tagManager").then();

import("./search-modal").then(page => {
  page.searchModal();
});

import("./language-location").then(page => {
  page.languageLocation();
});

import("./primary-header").then(page => {
  page.primaryHeader();
});

import("./primary-footer").then(page => {
  page.primaryFooter();
});


import("./vlite").then(page => {
  modules.vLite = page.vlite;
  if (document.querySelectorAll('.js-vlite').length > 0) {
    page.vlite();
  }
});


import("./noscroll").then(page => {
  page.noscroll();
  modules.noscroll = page.noscroll;
});



if (document.querySelector(".js-iframe-icon")) {
  import("./iframe-placeholder");
}

if (document.querySelector("body")) {
  import("./iframe-resizer").then(page => {  
    page.iframeResizer();
  })
}



if (document.getElementById("livezilla-script")) {
  import("./livezilla").then(page => {
    page.liveZilla();
  });
}

if (document.querySelector(".js-hotspot-board")) {
  import("./react/hotspots").then(page => {
    page.hotspots();
    modules.hotspots = page.hotspots;
  });
}

if (document.querySelector("#product-options-app")) {
  import("./react/product-options").then(page => {
    page.productOptions();
  });
}

if (document.querySelector("#stores-app")) {
  import("./react/store-search").then(page => {
    page.storeSearch();
  });
}

if (document.querySelector(".js-load-more-blogs")) {
  import("./blog-listing").then(page => {
    page.blogListing();
    modules.blogListing = page.blogListing;
  });
}

if (document.querySelector(".js-journal-popup")) {
  import("./journal-popup").then(page => {
    page.journalPopup();
  });
}

if (document.querySelector(".checkout-page")) {
  import("./checkout").then(page => {
    page.checkout();
  });
}

if (document.querySelector(".js-card-details-form")) {
  import("./checkout-card-details").then(page => {
    page.checkoutCardDetails();
  });
}

if (document.querySelector(".js-complete-order-page")) {
  import("./checkout-complete-order").then(page => {
    page.checkoutCompleteOrder();
  });
}

if (document.querySelector(".js-checkout-confirmation-page")) {
  import("./checkout-confirmation-page").then(page => {
    page.checkoutConfirmation();
  });
}

if (document.getElementById("checkout-gift-message-app")) {
  import("./react/checkout").then(page => {
    page.checkoutGiftMessage();
  });
}

if (document.querySelector(".js-post-code-search-autocomplete")) {
  import("./AddressLookUp");
}

if (document.querySelector(".js-check-balance")) {
  import("./checkbalance");
}

if (document.querySelector(".js-product-list-filters")) {
  import("./product-list-filters").then(page => {
    page.productListFilters();
    modules.productListFilters = page.productListFilters;
  });
}

if (document.querySelector(".js-career_filter")) {
  import("./careerlistfilter").then(page => {
    page.careerfilter();
  });
}

if (document.querySelector(".js-datepicker")) {
  import("./datepicker").then(page => {
    page.datepicker();
  });
}

if (
  document.querySelector(".js-slick-carousel") ||
  document.querySelector(".js-slick-carousel-text") ||
  document.querySelector(".js-slick-carousel-slideshow")
) {
  import("./slideshow").then(page => {
    page.slideshow();
    modules.slideshow = page.slideshow;
  });
}

if (document.querySelector(".js-glider") || document.querySelector('.js-modal-trigger')) {
  import("./glider").then(page => {
    page.gliderCarousel();
    modules.gliderCarousel = page.gliderCarousel;
  });
}

if (
  document.querySelector(".js-toggle-password-show-hide") ||
  document.querySelector(".js-check-old-password")
) {
  import("./password").then(page => {
    page.passwordShowHide();
  });
}

if (document.querySelector(".js-gift-card")) {
  import("./checkout-gift-card").then(page => {
    page.checkoutGiftCard();
  });
}

if (document.querySelector(".js-add-to-basket")) {
  import("./add-to-basket").then(page => {
    page.addToBasket();
  });
}

if (
  document.querySelector(".js-gift-card-add-to-basket") ||
  document.querySelector(".js-e-gift-card-add-to-basket")
) {
  import("./add-gift-card-to-basket").then(page => {
    page.addGiftCardToBasket();
  });
}

if (document.querySelector(".js-sticky")) {
  import("./sticky").then(page => {
    page.stickyElement();
  });
}

if (document.querySelector(".js-social-share")) {
  import("./social-share").then(page => {
    page.socialShare();
  });
}

if (document.querySelector(".js-textarea-field")) {
  import("./textarea").then(page => {
    page.textareaCharacterCount();
  });
}

if (document.querySelector(".js-sub-nav__list-item")) {
  import("./subnav").then(page => {
    page.subnav();
    modules.subnav = page.subnav;
  });
}

if (document.querySelector(".js-landing-hero-wrapper")) {
  import("./hero-landing-page").then(page => {
    page.heroLandingPage();
    modules.heroLandingPage = page.heroLandingPage;
  });
}

if (document.querySelector("input[type=file]")) {
  import("./file-upload").then(page => {
    page.fileUpload();
  });
}

if (
  document.querySelector(".js-masonry-list") ||
  document.querySelector(".js-masonry-navlist")
) {
  import("./masonry").then(page => {
    page.masonry();
    modules.masonry = page.masonry;
  });
}

if (document.querySelector(".js-edit-userprofile")) {
  import("./user-edit").then(page => {
    page.userEditShowHide();
  });
}

if (document.querySelector(".js-brochureaddress-selection")) {
  import("./brochure").then(page => {
    page.brochureAddressShowHide();
  });
}
if (document.querySelector(".js-edit-address")) {
  import("./edit-address").then(page => {
    page.editAddress();
  });
}

if (document.querySelector("select.js-choices")) {
  import("./actionlist").then(page => {
    page.actionlist();
    modules.actionlist = page.actionlist;
  });
}

if (document.querySelector(".js-choices-combo-box")) {
  import("./combobox").then(page => {
    page.combobox();
  });
}

if (document.querySelector(".js-expand-on-click")) {
  import("./collapsible").then(page => {
    page.collapsible();
    modules.collapsible = page.collapsible;
  });
}

if (document.querySelector(".js-buttons-tabs")) {
  import("./buttons-tab").then(page => {
    page.buttonsTab();
    modules.buttonsTab = page.buttonsTab;
  });
}

if (document.querySelector(".js-banner-close")) {
  import("./banner").then(page => {
    page.banner();
    modules.banner = page.banner;
  });
}

if (document.querySelector(".js-breadcrumb")) {
  import("./breadcrumb").then(page => {
    page.breadcrumb();
  });
}

if (document.querySelector(".js-loading")) {
  import("./loading").then(page => {
    page.loading();
    modules.loading = page.loading;
  });
}

if (document.querySelector(".lax")) {
  import("./lax").then(page => {
    page.laxxx();
    modules.laxxx = page.laxxx;
  });
}

if (document.querySelector(".js-toggle-class-on-focus")) {
  import("./selectbox").then(page => {
    page.initializeSelectBox();
  });
}

if (
  document.querySelector(".js-plus") ||
  document.querySelector("#js-selectedQuantity")
) {
  import("./quantity").then(page => {
    page.quantity();
  });
}

modules.restart = (): void => {
  var modules = window.modules as Object;
  for (var i = 0; i < Object.keys(modules).length; i++) {
    if (i == 0 || typeof (window.modules[Object.keys(modules)[i]]) != "function") {
      continue
    }

    if (!!window.modules) {
      window.modules[Object.keys(modules)[i]]();
    }
  }
}

initLazySizes();
fallbackImage();
passiveEventListeners();
