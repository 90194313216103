import "lazysizes";

export const initLazySizes = () => {
  // when lazyloading images in a carousel, make sure to refresh the slider
  document.addEventListener("lazyloaded", (e: Event) => {
    const t = e.target as HTMLElement | null;
    if (t?.parentElement?.classList.contains("blur-up")) {
      t?.parentElement?.classList.add("loaded");
    }
  });
};
